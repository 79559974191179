<template>
    <v-sheet>
        <v-card flat>
            <v-simple-table class="border-table">
                <template v-slot:default>
                    <thead>
                    <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                        <th class="text-center text-h6">납부월</th>
                        <th class="text-center text-h6">이용기간</th>
                        <th class="text-center text-h6">청구금액 (원)</th>
                        <th class="text-center text-h6">납부금액 (원)</th>
                        <th class="text-center text-h6">납부일자</th>
                        <th class="text-center text-h6">상세</th>
                    </tr>
                    </thead>
                    <tbody style="color:#555555;">
                    <template v-for="(billItem, billIdx) in billList">
                        <tr style="height:68px;" :key="'bill_' + billIdx">
                            <td class="text-center" style="font-size:20px;">{{ billItem.bill_month | dateMonth }}</td>
                            <td class="text-center" style="font-size:20px;">{{ billItem.svc_start_date | dateMin }} ~ {{ billItem.svc_end_date | dateMin }}</td>
                            <td class="text-center" style="font-size:20px;">{{ billItem.billing_amount | number }}</td>
                            <td class="text-center" style="font-size:20px;">
                                <span v-if="billItem.paid_amount > 0">{{ billItem.paid_amount | number }}</span>
                                <span v-else-if="billItem.betr_tid && billItem.bill_exec_result_code != '00'">납부실패</span>
                                <span v-else>-</span>
                            </td>
                            <td class="text-center" style="font-size:20px;">
                                <span v-if="billItem.paid_amount > 0">{{ billItem.paid_dt | dateMin }}</span>
                                <span v-else>-</span>
                            </td>
                            <td class="text-center" style="font-size:20px;">
                                <v-btn icon @click="openDetailList(billItem)" v-if="billItem.billing_amount > 0">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <tr style="height:69px;" v-if="!billList || billList.length == 0">
                        <td class="text-center" style="font-size:20px;" colspan="6">조회된 결과가 없습니다</td>
                    </tr>

                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="modal" persistent max-width="800" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'detailList'">
                <popup-bill-detail-list v-on:cancel="closePopup"></popup-bill-detail-list>
            </v-flex>
        </v-dialog>
    </v-sheet>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'tile_bill_list',
    components: {
        'popupBillDetailList': () => import('@/components/pay/popup_bill_detail_list.vue'),
    },
    data: () => ({
        modal: false,
        dialogMode: 'none'
    }),
    created() {
    },
    computed: {
        ...mapGetters({
            billList: 'pay/getBillList',
        }),
    },
    methods: {
        openDetailList(item) {
            this.$store.commit('pay/setCurBill', item)
            this.$store.dispatch('pay/getBillDetailList', {
                bill_seqno: item.bill_seqno,
                scb: (billDetailList, va) => {
                    va.dialogMode = 'detailList'
                    va.modal = true
                }, fcb: (err, va) => {
                }, va: this
            })
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },

    }
}
</script>